<template>
  <t-popup placement="bottom-right" trigger="click" v-model="visible" hideEmptyPopup @visible-change="onVisibleChange">
    <template #triggerElement>
      <t-avatar :class="{ 'z-notification': true, 'z-blink': store.UnReadMessages.length > 0 }">
        <NotificationIcon class="z-notification__icon" :size="17"></NotificationIcon>
      </t-avatar>
    </template>
    <template #content v-if="visible">
      <t-tabs v-model="activeTab" style="width: 300px">
        <t-tab-panel value="unread" :destroy-on-hide="false">
          <template #label>
            <span>未读</span>
            <ClearIcon v-if="activeTab == 'unread' && ClearURL" style="margin-left: 10px" @click="onClear" />
          </template>
          <t-list style="height: 80vh" :scroll="{ type: 'virtual' }">
            <t-list-item v-for="item in store.UnReadMessages" :key="item">
              <t-notification :title="item.SenderName">
                <template #content>
                  <div>{{ item.Content }}</div>
                  <div class="z-time">{{ item.SendTime }}</div>
                </template>
              </t-notification>
            </t-list-item>
          </t-list>
        </t-tab-panel>
        <t-tab-panel value="read" :destroy-on-hide="false">
          <template #label>
            <span>已读</span>
          </template>
          <t-list style="height: 80vh" :scroll="{ type: 'virtual' }">
            <template v-for="item in store.ReadMessages" :key="item">
              <t-list-item>
                <t-notification :title="item.SenderName">
                  <template #content>
                    <div>{{ item.Content }}</div>
                    <div class="z-time">{{ item.SendTime }}</div>
                  </template>
                </t-notification>
              </t-list-item>
            </template>
          </t-list>
        </t-tab-panel>
      </t-tabs>
    </template>
  </t-popup>
</template>

<script setup>
import { ClearIcon, NotificationIcon } from "tdesign-icons-vue-next";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useMessageStore } from "../../../stores/useMessageStore";
import { KeepThisPage, LeaveThisPage } from "../../../utils/common";
import { HttpPost } from "../../../utils/network";

const props = defineProps({
  ReadURL: String,
  UnReadURL: String,
  ClearURL: String,
  Render: String || Function,
});

const visible = ref(false);

const activeTab = ref("unread");

const store = useMessageStore();

function onClear() {
  HttpPost(props.ClearURL, {}, () => store.loadMessage("unread", props.UnReadURL));
}

function onVisibleChange(value) {
  if (value) KeepThisPage();
  else LeaveThisPage();
}

const stopWatchs = [
  watch(
    () => activeTab.value,
    (value) => {
      switch (value) {
        case "read":
          store.loadMessage("read", props.ReadURL);
          break;
        case "unread":
          store.loadMessage("unread", props.UnReadURL);
          break;
      }
    }
  ),
];

onMounted(() => {
  store.initTask(props.UnReadURL);
});

onBeforeUnmount(() => {
  stopWatchs.forEach((fnStop) => fnStop());
});
</script>

<style scoped>
.z-notification__icon {
  transform: translateY(-2px);
}

/** 红蓝的交替闪烁动画 */
@keyframes blink {
  0%,
  49.9% {
    background-color: var(--primary-color);
    color: var(--color-white);
  }

  50%,
  99.9% {
    background-color: var(--red-600);
    color: var(--color-white);
  }
}

.z-notification.z-blink {
  animation: blink 1s infinite;
}

:deep(.t-list-item) {
  padding: 0;
  margin-top: 6px;
}

.z-time {
  font-size: smaller;
  text-align: right;
}
</style>
