<template>
  <div class="z-pages-view">
    <auto-height scroll>
      <!-- <template #header>
                <button-bar :buttons="buttons" v-model="model" @click="onClick"></button-bar>
            </template> -->
      <template #default="{ height }">
        <div :style="{ height: height + 'px', overflowY: 'auto' }">
          <edit-panel v-bind="$props" v-model="model" :Readonly="Readonly"></edit-panel>
        </div>
      </template>
      <template #footer>
        <button-bar :buttons="buttons" v-model="model" @click="onClick" footer></button-bar>
      </template>
    </auto-height>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted } from "vue";
import autoHeight from "../src/components/base/auto-height.vue";
import buttonBar from "../src/components/base/button-bar.vue";
import editPanel from "../src/components/base/edit-panel.vue";
import { HasContent, RegisterEvent, toFn } from "../src/utils/common";
import { useAppStore } from "../src/stores/useAppStore";
import { appsettings } from "../appsettings";
import { useStickyToolStore } from "../src/stores/useStickyToolStore";

const model = defineModel();

const props = defineProps({
  /** 页面类型 */
  pagetype: String,
  Tabar: Object,
  ColCount: { type: Number, default: 3 },
  Content: Object,
  Areas: Array,
  Tabs: Array,
  Annex: Object,
  Album: Object,
  SubContent: Object,
  SubContents: Object,
  Record: Object,
  FootContent: Object,
});

const Readonly = computed(() => props.pagetype == "view");

function renderBtnVisible(btn) {
  return RegisterEvent(
    getCurrentInstance(),
    "RenderBtnVisible",
    function () {
      if (["add", "edit", "view"].includes(props.pagetype)) {
        if (HasContent(btn.VisibleCode) && btn.VisibleCode.includes(props.pagetype) == false) return false;
        if (["保存", "取消"].includes(btn.Label)) return props.pagetype != "view";
      }
      return true;
    },
    btn,
    model.value
  );
}

const buttons = computed(() => {
  if (props.Tabar && props.Tabar.Buttons) return props.Tabar.Buttons.filter(renderBtnVisible);
  return [];
});

function onClick(btn) {
  toFn(btn)(btn, model.value);
}

onMounted(() => {
  useAppStore().__bindInstance(getCurrentInstance());
  if (appsettings.Page.HelpButton) useStickyToolStore().AddStandardStickyTool("help");
});
</script>

<style scoped>
.z-pages-view {
  height: 100%;
  overflow: hidden;
}
</style>
