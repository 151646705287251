import { isUndefined } from "lodash";
import { useAppStore } from "../stores/useAppStore";
import { Loading, Toast } from "./dialog";
import { HttpUpload } from "./network";
import { ViteSetting } from "../config/vitesetting";

/** 多个这个表达式匹配，返回匹配正则
 * @param  {string} value 匹配对象
 * @param  {...any} regs 正则表达式
 * @returns {number} 返回最先匹配索引，-1则未找到
 */
function RegexMatch(value, ...regs) {
  for (let i = 0; i < regs.length; i++) {
    if (regs[i].test(value)) return i;
  }
  return -1;
}

/**
 * 判断对象是否为空
 * @param {*} o
 */
function HasContent(o) {
  return o !== undefined && o !== null;
}

/**
 * 判断对象是否为空
 * @param {*} o
 */
function IsNullOrEmpty(o) {
  return o === undefined || o === null || String(o).trim() === "";
}

/**
 * 判断对象是否为空或0
 * @param {*} o
 */
function IsNullOrEmptyOrZero(o) {
  return IsNullOrEmpty(o) || o == 0;
}

const emptyFn = function () {
  //空函数
};

/**
 * 转换为函数
 * @param {null | function | object} fn
 * @returns {function} 返回源函数或者空函数
 */
function toFn(fn) {
  if (HasContent(fn)) {
    switch (typeof fn) {
      case "function":
        return fn;
      case "string":
        if (fn in window && typeof window[fn] === "function") return window[fn];
        try {
          var _fn = eval(fn);
          if (typeof _fn === "function") return _fn;
        } catch {}
        break;
      case "object":
        // 如果是按钮类型
        if ("Action" in fn) {
          if (fn.Type == "file") {
            var loading = Loading("正在上传文件，请稍候……");
            HttpUpload(
              fn.Url,
              fn.IsMult,
              {},
              null,
              (res) => {
                loading.close();
                toFn(fn.Action)(fn, res.data);
              },
              loading.close
            );
          } else {
            return toFn(fn.Action);
          }
        }
        break;
    }
  }
  return emptyFn;
}

/** 匹配设置属性
 * @param {object} base 基础值
 * @param {object} option 匹配值
 * @param {object} alias 匹配别名
 * @returns 匹配后的值
 */
function MatchOption(base, option, alias) {
  if (option) {
    Object.entries(option).forEach(([key, value]) => {
      if (key in alias) {
        if (alias[key] in base) base[alias[key]] = value;
      } else {
        if (key in base) base[key] = value;
      }
    });
  }
  return base;
}

/** CallBack回调方法转换成Promise异步方法
 * @template T
 * @param {(...args:T)=> void} fn 原函数
 * @param {Number} index 回调起始位置
 * @param {Boolean} bFail 是否有错误事件
 * @param {Boolean} bComplete 是否有完成事件
 * @returns {(...args:T)=> Promise} 转换函数
 */
function toPromise(fn, index, bFail, bComplete) {
  return function () {
    return new Promise((resolve, reject) => {
      let events = [(res) => resolve(res)],
        args;
      if (bFail !== false) events.push((err) => reject(err));
      if (bComplete !== false) events.push(undefined);
      if (index > 0) {
        args = [...arguments];
        args.splice(index, 0, ...events);
      } else {
        args = [...arguments, ...events];
      }
      try {
        fn.call(this, ...args);
      } catch (err) {
        reject(err);
      }
    });
  };
}

/** Promise异步方法转换CallBack回调方法
 * @param {function():Promise} fn 原函数
 * @param {function} success 成功事件
 * @param {function} fail 失败事件
 * @param {function} complete 完成事件
 * @returns {function} 转换函数
 */
function toCallback(fn, success, fail, complete) {
  if (success && typeof success != "function") {
    fail = fail || success.fail;
    complete = complete || success.complete;
    success = success.success;
  }
  return function () {
    try {
      let resFn = fn.call(this, ...arguments);
      if (resFn instanceof Promise) {
        resFn
          .then((res) => toFn(success)(res))
          .catch((err) => toFn(fail)(err))
          .finally(() => toFn(complete)());
      } else {
        toFn(success)(resFn);
        toFn(complete)();
      }
    } catch (err) {
      toFn(fail)(err);
      toFn(complete)();
    }
  };
}

//字符参数转义，获取参数解析，支持window、config、data的属性，格式${param}
const _evalReg = /\$\{[^\}]*\}/g;

/** 字符串 获取参数转义
 * @param {String} str
 * @param {Object} other
 * @returns {String}
 */
function ParseParameters(str, other) {
  let app = useAppStore(),
    value,
    keys = str.match(_evalReg);
  if (keys) {
    for (var key of keys) {
      key = key.substring(2, key.length - 1);
      value = undefined;
      if (other && key in other) {
        value = other[key];
      }
      if (value === undefined && typeof app !== "undefined") {
        if (key in app.config) value = app.config[key];
        else if (key in app.data) value = app.data[key];
      }
      if (value === undefined && key in window) value = window[key];
      if (value === undefined) {
        try {
          var val = eval(`(${key})`);
          if (typeof val != "undefined") value = val;
        } catch (e) {}
        key = key.replace(/\$/g, "\\$").replace(/\./g, "\\.").replace(/\[/g, "\\[").replace(/\]/g, "\\]");
      }
      if (value === undefined || value == null) value = "";
      //替换值
      str = str.replace(new RegExp(`\\$\\{${key}\\}`, "g"), value);
    }
  }
  return str;
}

/** 复制到剪贴板
 * @param {Object} value 要剪贴的值
 */
function CopyToClipboard(value) {
  var dom = document.createElement("textarea");
  dom.value = value;
  document.body.appendChild(dom);
  dom.select();
  document.execCommand("Copy");
  document.body.removeChild(dom);
  Toast("已复制到粘贴板", "success");
}

function EqualConverter(key, value) {
  //忽略$开头的数据
  if (key.startsWith("$") && key != "$rowChecked") return undefined;
  if (value === null) return undefined;
  switch (typeof value) {
    case "function":
      return value.name + String(value);
    case "number":
    case "bigint":
      return String(value);
    default:
      return value;
  }
}

/** 比较两个对象的值是否一致 */
function EqualsObject(a, b) {
  return JSON.stringify(a, EqualConverter) == JSON.stringify(b, EqualConverter);
}

/** 注册windows事件，注册Before和After时机点
 * @param {Object} sender 事件调用者
 * @param {String} name 事件名称
 * @param {Function} callback 事件回调
 * @param {Array} args 其他参数
 * @returns {Object} 返回结果
 */
function RegisterEvent(sender, name, callback, ...args) {
  sender = sender || window;
  var result;
  toCallback(toFn(`OnBefore${name}`), (next) => {
    if (next !== false) {
      toCallback(toFn(callback), (res) => {
        result = res;
        toCallback(toFn(`OnAfter${name}`), (res) => {
          if (!isUndefined(res)) {
            result = res;
          }
        }).call(sender, ...args, result);
      }).apply(sender, args);
    }
  }).apply(sender, args);
  return result;
}

const file_reg = /[^\/]*\.[^\/\.]*$/;
const file_guid_reg = /_-_[^_\.]{32}/;
const file_uri_reg = /[^\/\?\&\/\%\=\+]+(?=\?|$)/;
/** 获取文件名称 */
function GetFileName(src, ext) {
  if (IsNullOrEmpty(src)) return "";
  if (src.startsWith("http")) {
    let regs = src.match(file_uri_reg);
    if (regs.length > 0) {
      let name = regs[0];
      if (name.includes(".") || IsNullOrEmpty(ext)) return name;
      else return `${name}.${ext}`;
    } else return;
  } else {
    let regs = src.match(file_reg);
    if (regs.length > 0) return regs[0].replace(file_guid_reg, "");
  }
  return HasContent(ext) ? `image${Date.now}.${ext}` : "";
}

/**
 * 搜索字符串
 * @param {string} str
 * @param {string} word 内容
 * @returns {number} 匹配位置
 */
function SearchWordPosition(str, word) {
  if (IsNullOrEmpty(str) || IsNullOrEmpty(word)) return -1;
  return str.toLowerCase().indexOf(word.toLowerCase());
}

/** 设置本地缓存
 * @param {String} key 键
 * @param {Object} value 值
 * @returns {Promise<Boolean>} 是否设置成功
 */
function SetStorageAsync(key, value) {
  return new Promise((resolve, reject) => {
    try {
      if (value === undefined) localStorage.removeItem(key);
      else
        localStorage.setItem(
          key,
          JSON.stringify(value, (key, value) => (value === null ? undefined : value))
        );
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}

/** 获取移除本地缓存
 * @param {String} key 键
 * @returns {Promise<Boolean>} 是否移除成功
 */

function RemoveStorageAsync(key) {
  return new Promise((resolve, reject) => {
    try {
      localStorage.removeItem(key);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}

/** 获取本地缓存
 * @param {String} key 键
 * @returns {Promise<Object>} 返回设置结果
 */
function GetStorageAsync(key) {
  return new Promise((resolve, reject) => {
    try {
      let value = localStorage.getItem(key);
      if (HasContent(value)) {
        resolve(JSON.parse(value));
      } else resolve(null);
    } catch (err) {
      reject(err);
    }
  });
}

/** 设置本地缓存
 * @param {String} key 键
 * @param {Object} value 值
 * @returns {Boolean} 是否设置成功
 */
function SetStorageSync(key, value) {
  try {
    if (value === undefined) localStorage.removeItem(key);
    else
      localStorage.setItem(
        key,
        JSON.stringify(value, (key, value) => (value === null ? undefined : value))
      );
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

/** 获取移除本地缓存
 * @param {String} key 键
 * @returns {Boolean} 是否移除成功
 */

function RemoveStorageSync(key) {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    console.err(err);
    return false;
  }
}

/** 获取本地缓存
 * @param {String} key 键
 * @returns {Object} 返回设置结果
 */
function GetStorageSync(key) {
  try {
    let value = localStorage.getItem(key);
    if (HasContent(value)) return JSON.parse(value);
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * 金额格式化
 * @param {number} num
 * @param {*} precision
 * @returns
 */
function MoneyFormat(num, precision) {
  if (isNaN(num)) return "";
  return Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: precision || 2,
    maximumFractionDigits: precision || 2,
  }).format(num);
}

/** 弹出框时专用，不然iframe移开的时候无法穿透，弹窗不会消失，搭配LeaveThisPage一起使用 */
function KeepThisPage() {
  document.getElementById("app").classList.add("on-page");
}

/** 关闭弹窗后，移除iframe控制，这样iframe就可以正常点击操作，搭配KeepThisPage一起使用 */
function LeaveThisPage() {
  document.getElementById("app").classList.remove("on-page");
}

/** 获取缩略图
 *  @param { String } url 图片路径
 */
function GetThumbnail(url) {
  if (url) return `${url}?x-oss-process=image/resize,h_100`;
  else return "about:blank";
}
/** 获取非缩略图
 *  @param { String } url 图片路径
 */
function GetImageView(url) {
  if (url) return `${ViteSetting.IsApp ? ViteSetting.ApiPrefix : ""}` + url;
  else return "about:blank";
}

export {
  RegexMatch,
  HasContent,
  IsNullOrEmpty,
  IsNullOrEmptyOrZero,
  emptyFn,
  toFn,
  toPromise,
  toCallback,
  MatchOption,
  ParseParameters,
  CopyToClipboard,
  EqualsObject,
  RegisterEvent,
  GetFileName,
  SearchWordPosition,
  SetStorageAsync,
  RemoveStorageAsync,
  GetStorageAsync,
  SetStorageSync,
  RemoveStorageSync,
  GetStorageSync,
  MoneyFormat,
  KeepThisPage,
  LeaveThisPage,
  GetThumbnail,
  GetImageView,
};
