<template>
  <menu-bar :class="{ 'z-button-bar': true, 'z-footer-bar': footer }" v-if="buttons.length > 0">
    <template #start>
      <template v-for="btn in buttons" :key="btn">
        <z-button v-bind="btn" @click="onClick"></z-button>
      </template>
    </template>
  </menu-bar>
</template>

<script setup>
import menuBar from "primevue/menubar";
import ZButton from "../base/button.vue";

const model = defineModel();
const props = defineProps({
  buttons: { type: Array, default: [] },
  footer: { type: Boolean, default: false },
});
const emits = defineEmits(["click"]);

function onClick() {
  emits("click", ...arguments);
}
</script>

<style scoped>
.z-button-bar {
  padding-left: 0.4em;
  padding: 1.5px;
  border: none;
  box-shadow: var(--z-box-shadow);
  border-radius: 0;
}

.z-footer-bar {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
}
</style>
