<template>
  <PrimeSplitButton
    :class="{
      'z-button': true,
      'z-button__button': HasContent(Options) == false || Options.length == 0,
      'z-button__dropdown': HasContent(Action) == false && Options && Options.length > 0,
    }"
    :severity="Style"
    :model="buttons"
    size="small"
    text
    @click="onClick">
    <span>{{ Label }}</span>
    <template #menubuttonicon>
      <span
        :class="{
          'z-button__dropdown-label': Options && Options.length > 0,
        }"
        >{{ Label }}</span
      >
      <CaretDownSmallIcon size="18px" style="transform: translateY(-1px)"></CaretDownSmallIcon>
    </template>
  </PrimeSplitButton>
</template>

<script setup>
import { uniqueId } from "lodash";
import PrimeSplitButton from "primevue/splitbutton";
import { CaretDownSmallIcon } from "tdesign-icons-vue-next";
import { computed } from "vue";
import { ZButtonProperty } from "../../models/ZButtonProperty";
import { HasContent } from "../../utils/common";

defineOptions({ name: "ZButton" });

const props = defineProps({
  /** 按钮主题样式 */
  variant: { type: String, default: "text" },
  Label: String,
  /** 按钮类型 */
  Type: String,
  /** 按钮样式：info、success、danger、waring */
  Style: String,
  /** 数据权限 */
  Code: String,
  /** 按钮操作事件，文件类型时 */
  Action: String || Function,
  /** 是否是分组按钮 */
  IsGroup: { type: Boolean, default: null },
  /** 额外按钮集合
   * menu 为下拉按钮
   * print 模板清单
   */
  Options: Array,

  /** file，文件上传请求地址 */
  Url: String,
  /** file，是否允许上传多个文件 */
  IsMult: { type: Boolean, default: null },

  PageType: String,
  PageAction: String,
  BusinessName: String,
  SaveAction: String,
  BatchSaveAction: String,
  IsAsync: { type: Boolean, default: null },
  Params: Array,
  ApproveButton: String,

  BusinessType: String,
  Business: String,
  Table: String,
  Select: String,
  Alias: String,

  /** 按钮是否显示 */
  Visible: { type: Boolean, default: null },
  /** 按钮显示场景：add、edit、view、select、list */
  VisibleCode: Array,
  /** 按钮只读 */
  Readonly: { type: Boolean, default: null },
  /** 按钮只读场景：add、edit、view、select、list */
  ReadonlyCode: Array,
  /** 按钮额外参数，底层不用，特殊处理参数 */
  Paramenters: Object,
});

const emits = defineEmits(["click"]);

/** 按钮集合
 * @type {import("primevue/menuitem").MenuItem[]}
 */
const buttons = computed(() => {
  if (props.Options && props.Options.length > 0) return props.Options.map((op) => ZButtonToMenuItem(op));
  else return [];
});

/**
 * 按钮集合转菜单集合
 * @param {ZButtonProperty} btn
 * @returns {import("primevue/menuitem").MenuItem}
 */
function ZButtonToMenuItem(btn) {
  return {
    key: uniqueId("btn_"),
    label: btn.Label,
    visible: btn.Visible,
    disabled: btn.Readonly,
    items: btn.Options && btn.Options.length > 0 ? btn.Options.map((op) => ZButtonToMenuItem(op)) : null,
    command() {
      emits("click", btn);
    },
  };
}

function onClick() {
  emits("click", props);
}
</script>

<style scoped>
.z-button + .z-button {
  margin-left: 4px;
}

button.z-button.p-button-text,
.z-button :deep(.p-button-text) {
  font-size: 14px;
  line-height: 18px;
  padding: 0 6px;
  border-radius: 4px;
  margin: 2px;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
}

.z-button.p-button-text:hover,
.z-button.p-button-text:hover :deep(.p-button-text) {
  border-bottom-color: var(--primary-500);
}

.z-button :deep(.p-button-text) {
  background: var(--primary-500) !important;
  color: var(--primary-50) !important;
}

.z-button :deep(.p-button-text.p-button-text.p-button-danger) {
  background: var(--red-600) !important;
}

.z-button :deep(.p-splitbutton-menubutton) {
  padding: 0 1px;
  width: fit-content;
}

.z-button__button :deep(.p-splitbutton-menubutton) {
  display: none;
}

.z-button__dropdown :deep(.p-splitbutton-menubutton) {
  padding: 0 3px 0 6px;
}

.z-button__dropdown :deep(.p-splitbutton-defaultbutton) {
  display: none;
}

.z-button:not(.z-button__dropdown) .z-button__dropdown-label {
  display: none;
}
</style>

<style>
.p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 6px;
  font-size: 14px;
}
</style>
