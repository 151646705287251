<template>
  <t-switch v-model="inputValue" active-color="#4FB01F" :disabled="Readonly === true" @change="onChange">
    <template #label="{ value }">
      <template v-if="Style == 'sex'">
        <GenderMaleIcon v-if="value"></GenderMaleIcon>
        <GenderFemaleIcon v-else></GenderFemaleIcon>
      </template>
      <template v-if="Style == 'theme'">
        <SunnyIcon v-if="value"></SunnyIcon>
        <MoonIcon v-else></MoonIcon>
      </template>
      <template v-else>
        <CheckIcon v-if="value"></CheckIcon>
        <CloseIcon v-else></CloseIcon>
      </template>
    </template>
  </t-switch>
</template>

<script setup>
import { onBeforeUnmount, ref, watch } from "vue";
import { HasContent } from "../../utils/common";
import { CheckIcon, CloseIcon, GenderFemaleIcon, GenderMaleIcon, MoonIcon, SunnyIcon } from "tdesign-icons-vue-next";

const props = defineProps({
  Field: String,
  Readonly: { type: Boolean, default: null },
  DiscernNull: { type: Boolean, default: null },
  Style: String,
  ActiveText: String,
  InActiveText: String,
});

const emits = defineEmits(["change"]);

const inputValue = ref(null);

const model = defineModel({ type: Boolean, default: null });

/** [0] FalseColor, [1] NullColor */
const colors = ["#909399", "#DE9930"];
const inactiveColor = ref(colors[0]);

function onChange(value) {
  if (inputValue.value != model.value) {
    let oldValue = model.value;
    model.value = inputValue.value;
    emits("change", value, oldValue);
  }
}

/** 绑定内部值 */
function bindValue(value) {
  inputValue.value = value;
  if (props.DiscernNull) {
    if (HasContent(value)) inactiveColor.value = colors[0];
    else inactiveColor.value = colors[1];
  }
}

const stopWatchs = [watch(() => model.value, bindValue, { immediate: true })];

onBeforeUnmount(() => {
  stopWatchs.forEach((stop) => stop());
});
</script>

<style scoped>
.t-switch {
  zoom: 0.8;
}

.t-switch.t-is-checked {
  background-color: var(--td-success-color-6);
}

.t-switch:not(.t-is-checked) {
  background-color: #545454;
  /* background-color: var(--gray-300); */
}
</style>
